.post_image {
  img {
    height: 200px;
    width: 200px;
  }
}
.individual_post_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  background-color: #faf7ff;

}
.post_header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: #474554;
  margin: 10px 0;
}
.post_content {
  width: 100%;
  display: flex;
  justify-content: center;
  line-height: 1.5;
  font-size: 18px;
  text-align: justify;
}
.post_date{
  font-size: 22px;
  color: #605ef5;
}
.post_image{
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 25px 0;
  max-height: 500px;
  overflow: hidden;
  img{
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-height: 500px;
  }
}
.styled-paragraph {
  font-weight: normal;
  margin: 1em 0;
  line-height: 1.5;
  font-size: 18px;
  text-align: justify;
}

.styled-bold {
  font-weight: bold;
}