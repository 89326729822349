$blue: #342dfc;
$mobile: 480px;
$tablet: 800px;
$laptop: 1024px;
$desktop: 1300px;

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.slider_container {
  svg {
    fill: white;
    @media (max-width: $tablet) {
      width: 30px;
      height: 30px;
    }
  }
}

.slider_container_2 {
  position: relative;
  width: 80%;
  margin: 2% auto;
  img {
    border-radius: 8px;
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: 95%;
  }
  @media screen and (max-width: 768px) {
    width: 95%;
  }
  @media screen and (max-width: 1024px) {
    width: 95%;
  }
}

.slide {
  width: 100%;
  margin: 0 auto;
  position: relative;

  &.fade-in {
    animation: fadeIn 0.1s ease-in forwards;
  }

  &.fade-out {
    animation: fadeOut 0.1s ease-out forwards;
  }

  img {
    width: 100%;
    object-fit: cover;
    max-height: 75vh;
  }

  .slide_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.65);
   
    width: min(50%, 600px);
    max-height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -15%);
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    padding: clamp(15px, 3vw, 35px);
    overflow: hidden;

    .title {
      font-size: 24px;
    }

    p {
      padding: clamp(4px, 2vw, 10px) 0;
      &:first-child {
        padding-bottom: clamp(8px, 1.5vw, 20px);
        line-height: 1.3;
      }
      &:last-child {
        font-size: clamp(12px, 1.8vw, 18px);
        a {
          display: inline-block;
          width: min(80%, 300px);
          background-color: black;
          text-decoration: none;
          padding: clamp(6px, 1.2vw, 10px) clamp(8px, 1.5vw, 20px);
          color: white;
          font-size: clamp(11px, 1.6vw, 16px);
          &:hover {
            background-color: #1e1f1f;
          }
        }
      }
    }

    @media (max-width: 1024px) {
      width: min(60%, 500px);
      transform: translate(-50%, -15%);
      .title {
        font-size: clamp(12px, 2vw, 24px);
      }
    }

    @media (max-width: 768px) {
      width: min(70%, 400px);
      padding: clamp(10px, 2vw, 20px);
      transform: translate(-50%, -15%);
      .title {
        font-size: clamp(12px, 2vw, 22px);
      }

    }

    @media (max-width: 480px) {
      width: min(85%, 300px);
      padding: clamp(8px, 1.5vw, 15px);
      transform: translate(-50%, -15%);
      .title {
        font-size: clamp(10px, 1.8vw, 20px);
        white-space: normal;
        word-wrap: break-word;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1.4;
      }

    }
  }
}

.left_arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(50%, -50%);
}

.right_arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.btn-slide {
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in;
}

.left_arrow:hover,
.right_arrow:hover {
  color: white;
  background-color: rgba(203, 202, 202, 0.2);
}