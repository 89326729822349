$blue: #342dfc;
.hpp_post_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  .hpp_post_grid {
    margin: 0 auto;
    width: min(95%, calc(100% - 40px));
    max-width: 1550px;
    display: grid;
    grid-template-columns: repeat(60, 1fr);
    gap: clamp(10px, 1.5vw, 20px);
    padding: clamp(8px, 1.5vw, 15px);
    box-sizing: border-box;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(auto-fit, minmax(min(100%, 280px), 1fr));
    }

    .hpp_grid_box {
      display: flex;
      flex-direction: column;
      background-color: #f1f1f1;
      gap: clamp(8px, 1.5vw, 15px);
      padding: clamp(6px, 1vw, 10px);
      width: 100%;
      height: auto;
      position: relative;
      bottom: 0;
      transition: background-color 0.3s ease-in-out, bottom 0.1s ease-in-out, border 0.1s ease-in-out;
      border: 2px double rgba(255,255,252,0);
      cursor: pointer;

      &:hover {
        background-color: #f0f0f0;
        bottom: 2px;
        border: 2px double rgba(81, 23, 163,0.8);
      }

      &:nth-child(1) {
        grid-column: 1 / -1;
        flex-direction: row;
        gap: clamp(10px, 2vw, 20px);
        min-height: auto;
        max-height: none;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        .box_img {
          flex: 0 0 40%;
          height: clamp(250px, 40vh, 400px);

          @media (max-width: 768px) {
            flex: none;
            width: 100%;
            height: clamp(200px, 30vh, 300px);
          }
        }

        .box_content {
          flex: 1;
          padding: clamp(10px, 2vw, 20px);

          .date_title {
            margin-bottom: clamp(8px, 1.5vw, 20px);

            .post_date {
              font-size: clamp(14px, 1.5vw, 22px);
            }

            li:last-child {
              font-size: clamp(18px, 2vw, 24px);
            }
          }

          .post_content {
            font-size: clamp(14px, 1.5vw, 18px);
          }
        }
      }

      @media (min-width: 1201px) {
        // Row 2: 2 posts (equal width)
        &:nth-child(2) {
          grid-column: span 30;
        }
        &:nth-child(3) {
          grid-column: span 30;
        }

        // Row 3: 3 posts (equal width)
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          grid-column: span 20;
        }

        // Row 4: 4 posts (equal width)
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10) {
          grid-column: span 15;
        }

        // Row 5: 5 posts (equal width)
        &:nth-child(11),
        &:nth-child(12),
        &:nth-child(13),
        &:nth-child(14),
        &:nth-child(15) {
          grid-column: span 12;
        }

        // Additional posts: 3 posts per row
        &:nth-child(n+16) {
          grid-column: span 20;
        }
      }

      &:not(:first-child) {
        .box_img {
          aspect-ratio: 16/9;
          height: clamp(150px, 25vh, 180px);
        }

        .box_content {
          padding: clamp(8px, 1.5vw, 15px);

          .date_title {
            margin-bottom: clamp(6px, 1vw, 10px);

            li:first-child {
              font-size: clamp(12px, 1.2vw, 14px);
            }

            li:last-child {
              font-size: clamp(14px, 1.5vw, 16px);
            }
          }

          .post_content {
            font-size: clamp(13px, 1.2vw, 15px);
            line-height: 1.4;
            padding: clamp(6px, 1vw, 15px);
          }
        }
      }

      .box_img {
        width: 100%;
        overflow: hidden;
        border-radius: 3px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 3px;
          transition: transform 0.3s ease;
        }
      }

      .box_content {
        background-color: white;
        border-radius: 3px;
        list-style: none;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .date_title {
          display: flex;
          gap: clamp(6px, 1vw, 10px);
          align-items: center;
          justify-content: center;
          li {
            &:first-child {
              color: #666;
            }

            &:last-child {
              font-weight: 500;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
        }

        .post_content {
          flex: 1;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
          color: #444;
        }
      }
    }
  }
}
.hp_more_btn_cont {
  width: min(95%, calc(100% - 40px));
  max-width: 1550px;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  padding: 0 clamp(8px, 1.5vw, 15px);
  box-sizing: border-box;
  
  .more_button{
    background-color: $blue;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    &:hover {
      background-color: #1e1f1f;
    }
  }
}
@media (max-width: 1200px) {
  .hpp_post_grid {
    width: min(90%, calc(100% - 30px)) !important;
  }
}

@media (max-width: 992px) {
  .hpp_post_grid {
    width: min(95%, calc(100% - 20px)) !important;
  }
}

@media (max-width: 768px) {
  .hpp_post_grid {
    width: min(100%, calc(100% - 16px)) !important;
  }
}

@media (max-width: 480px) {
  .hpp_post_container {
    padding: 8px;
    .hpp_post_grid {
      width: 100% !important;
      gap: 12px;
      padding: 8px;
      .hpp_grid_box {
        min-width: 0;
      }
    }
  }
}
