$blue: #342dfc;

$mobile: 480px;
$tablet: 800px;
$laptop: 1024px;
$desktop: 1300px;

nav {
  ul {
    display: flex;
    li {
      list-style: none;
      padding: 15px;
      margin: 0 5px;
      a {
        text-decoration: none;
        position: relative;
      }
    }
  }
}
#reservation_li{
  display: flex;
  width: 65%;

  div{
    height: 18px;
    width: 18px;
    position: relative;
    top: 17px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: red;
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
    animation: glow 3s infinite;
    transform-origin: center;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }
  }
}
@keyframes glow {
  0% {
    transform: scale(1);
    background-color: red;
  }
  25% {
    transform: scale(1.2);
    background-color: #FFA500;
  }
  50%{
    transform: scale(1);
    background-color: #00ff00;
  }
  75%{
    transform: scale(1.2);
    background-color: white;
  }
  100%{
    transform: scale(1);
    background-color: red;
  }}
#reservation_link {
  text-align: center;
  font-size: 26px;
  background-color: #f8ebff;
  padding: 8px;
  border-radius: 2px;
  color:rgb(58, 85, 121) ;
  width: 100%;
  transition: all 0.2s ease-in;
  &:hover{
    background-color: #cdcfea;
  }
}
.navbar_1 {
  ul {
    justify-content: flex-end;
    align-items: center;
  }
  a {
    color: #cad4e1;
    transition: color 0.1s ease-in;
    &:hover {
      color: white;
    }
  }

}

.navbar_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  background-color: white;
  ul {
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    a {
      color: #363d42;
      &::after {
        content: "";
        height: 4px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -18px;
        opacity: 0;
        background-color: #5117a3;
        transition: all 0.1s ease-in;
        pointer-events: none;
      }
      &:hover::after {
        opacity: 1;
      }
      &.active::after {
        opacity: 1;
      }
    }
  }
  img {
    max-width: 50px;
    object-fit: contain;
    border-radius: 8px;
    position: relative;
    left: 0.5rem;
    top: 0.08rem;
  }
  svg {
    display: none;
  }
}
.navbar_1,
.navbar_2 {
  max-width: 1300px;
  margin: 0 auto;
}

.menu {
  display: none;
  position: absolute;
  top: 2rem;
  left: 1.1rem;
  flex-direction: column;
  justify-content: space-between;
  width: 1.8rem;
  height: 1.5rem;
  span {
    background-color: black;
    height: 0.15rem;
    width: 100%;
    border-radius: 0.2rem;
  }
}
.menu:hover {
  cursor: pointer;
}
.navTab {
  display: none;
  height: 5rem;
  width: 100%;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.nav_cont {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
}
.navbar_wrapper {
  background-color: $blue;
}
.nav2_wrapper {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    background-color: white;
}

.sidebar {
  position: fixed;
  top: 3.9rem;
  left: 0;
  height: 100vh;
  background-color: white;
  width: 100%;
  display: none;
  z-index: 1;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    li {
      width: 100%;
      a {
        color: #5f5f5f;
        transition: color 0.1s ease-in;
        &:hover {
          color: rgb(0, 0, 0);
        }
        &::after {
          content: "";
          height: 4px;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -18px;
          opacity: 0;
          background-color: #5117a3;
          transition: all 0.1s ease-in;
          pointer-events: none;
        }
        &:hover::after {
          opacity: 1;
        }
        &.active::after {
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: $laptop) {
  .navbar_1 {
    display: none;
  }
  .navbar_2 {
    margin: 0;
    padding: 5px;
    ul {
      display: none;
      li {
        margin: 20px 0;
      }
    }
    img {
      left: 0.5rem;
    }
    svg {
      position: absolute;
      top: 15px;
      left: 15px;
      display: flex;
      &:hover {
        cursor: pointer;
      }
      transition: all 0.75s ease-in-out;
    }
  }

  nav {
    ul {
      li {
        margin: 10px 0;
      }
    }
  }
}
