footer {
  width: 100%;
  background-color: #f2f2f2;
  
}
.footer_list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  width: 100%;
  li {
    margin: 25px;
    text-align: center;
    color: rgb(64, 64, 64);

    a {
      text-decoration: none;
      color: rgb(64, 64, 64);
    }
  }
  font-size: clamp(10px, 2.5vw, 14px);
}

@media (max-width: 480px) {
  .footer_list {
    flex-wrap: wrap;
    padding: 8px 0;
  }

  .footer_list li {
    margin: 8px;
    flex: 1 1 40%;
  }
}


/* footer.scss */

/* Footer Styling */
footer {
  background-color: #f1f1f1;
  padding: 30px 0;
  text-align: center;
}

/* Footer Container */
.footer-container {
  width: 80%;
  margin: 0 auto;
}

/* Footer Section */
.footer-section {
  margin-bottom: 30px;
}

/* Footer Title Styling */
.footer-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

/* Footer Columns Styling */
.footer-columns {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

/* Footer Column Styling */
.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

.footer-column ul li a:hover {
  color: #0073e6;
}

footer .footer-bottom {
  background-color: #e1e1e1;
  padding: 10px;
  text-align: center;
  font-weight: 600;
  color: #333;
}

/* Media Query for Footer */
@media (max-width: 768px) {
  footer .footer-container {
    flex-direction: column;
    align-items: center;
  }

  footer .social-links {
    flex-direction: column;
  }
}


/* footer.css */

/* Footer Styling */
.footer {
  text-align: center;
  background-color: #333;
  color: #fff;
}

.footer a {
  color: #FF9900;
  text-decoration: none;
  margin: 0 10px;
}

.footer a:hover {
  color: #e68a00;
}




