.preview-container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-frame {
  width: 200%;
  height: 73vh;
  margin: 20px 0;
  border: none;
  background-color: white;
}

.welcome-container {
  max-width: 1300px; /* Sets the maximum width of the container */
  width: 100%; /* Ensures the container takes up the full width of its parent */
  margin: 1px auto; /* Centers the container horizontally and adds 20px of margin on the top and bottom */
  padding: 20px; /* Adds 20px of padding inside the container */
  background-color: #f9f9f9; /* Sets a light gray background color */
  border-radius: 8px; /* Rounds the corners with an 8px radius */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow to give the container depth */
}

/* Adjust the image slider styles */
.image-slider {
  margin: 0; /* Remove margin to eliminate space */
  padding: 0; /* Remove padding if needed */
}

.main-header {
  font-size: 1.5rem;  /* Reduced font size */
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.sub-header {
  font-size: 1.2rem;  /* Reduced font size for the sub-header */
  color: #2b2b2b; 
  display: block; /* Ensures sub-header stays on the same line */
  margin-top: 10px;
}

.welcome-container p {
  font-size: 1rem;
  color: #555;
  line-height: 1;
  margin-bottom: 10px;
}






