// Variables
$primary-color: #fff;
$background-opacity: 0.1;
$border-radius: 8px;
$box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);

// Mixins
@mixin flex-center {
  display: flex;
  justify-content: center;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown {
  @include flex-center;
  gap: 20px;
  margin: 20px 0;

  &-item {
    @include flex-column-center;
    min-width: 80px;
    background-color: rgba($primary-color, $background-opacity);
    padding: 10px;
    border-radius: $border-radius;
  }

  &-value {
    font-size: 2rem;
    font-weight: bold;
    color: $primary-color;
    text-shadow: $box-shadow;
  }

  &-label {
    font-size: 0.9rem;
    color: rgba($primary-color, 0.9);
    margin-top: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

/* Responsive Design */
@media (max-width: 480px) {
  .countdown {
    gap: 10px;

    &-item {
      min-width: 60px;
      padding: 8px;
    }

    &-value {
      font-size: 1.5rem;
    }

    &-label {
      font-size: 0.8rem;
    }
  }
}
