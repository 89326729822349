// Variables
$max-width: 1300px;
$primary-bg: #f8f9fa;
$title-color: #1a237e;
$subtitle-color: #283593;
$text-color: #333;
$border-radius: 8px;
$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
$accent-color: #1a237e;

.terms-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: $max-width;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  padding-bottom: 0;

  @media (max-width: 768px) {
    width: 90%;
    padding: 15px;
    padding-bottom: 0;
  }

  footer {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    margin-top: auto;
    position: relative;
    bottom: 0;
  }

  .content-section {
    background-color: $primary-bg;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    padding: 30px;
    margin-bottom: 30px;
    flex: 1;

    .main-title {
      color: $title-color;
      font-size: 2.2rem;
      text-align: center;
      margin-bottom: 30px;
      font-weight: 600;

      @media (max-width: 768px) {
        font-size: 1.8rem;
      }
    }

    .section-title {
      color: $title-color;
      font-size: 1.5rem;
      margin: 25px 0 15px;
      font-weight: 600;
    }

    .subsection-title {
      color: $subtitle-color;
      font-size: 1.2rem;
      margin: 20px 0 10px;
      font-weight: 500;
    }

    p {
      color: $text-color;
      font-size: 1rem;
      line-height: 1.6;
      margin-bottom: 15px;
    }

    ul {
      list-style-type: disc;
      margin-left: 20px;
      margin-bottom: 15px;

      li {
        color: $text-color;
        font-size: 1rem;
        line-height: 1.6;
        margin-bottom: 8px;
      }
    }

    .disclaimer {
      background-color: #f5f5f5;
      padding: 20px;
      border-radius: $border-radius;
      margin-top: 30px;

      h1 {
        color: $title-color;
        margin-bottom: 15px;
        font-weight: 600;
      }

      p {
        font-size: 0.95rem;
        margin-bottom: 10px;
      }
    }
  }
}
