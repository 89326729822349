// Variables
$max-width: 1300px;
$primary-bg: #f8f9fa;
$title-color: #1a237e;
$subtitle-color: #283593;
$highlight-color: #ff5722;
$border-radius: 8px;
$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
$box-shadow-light: 0 1px 3px rgba(0, 0, 0, 0.1);
$accent-color: #1a237e;
// Mixins
@mixin card-base {
  background-color: $primary-bg;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}

@mixin section-title {
  color: $title-color;
  font-weight: 600;
  text-align: center;
}

.features-container {
  max-width: $max-width;
  margin: 0 auto;
  padding: 20px;
}
.cta-section {
  background-color: $accent-color;
  padding: 40px;
  color: white;
  border-radius: $border-radius;
  text-align: center;
  margin-top: 30px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: rgb(60, 76, 185) !important;
  }
  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: 600;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin: 0;
  }
}
.feature-section {
  @include card-base;
  padding: 25px;
  margin-bottom: 30px;

  .section-title {
    @include section-title;
    margin-bottom: 25px;
    font-size: 1.8rem;
  }

  .feature-content {
    h3 {
      color: $subtitle-color;
      margin: 20px 0 10px;
      font-size: 1.7rem;
    }
    h4{
      color: $subtitle-color;
    }
    p {
      margin-bottom: 20px;
      line-height: 1.6;
    }
  }
}

.benefits-list {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .benefit-item {
    background-color: white;
    padding: 15px;
    border-radius: $border-radius;
    box-shadow: $box-shadow-light;

    p {
      margin: 0;
      line-height: 1.5;
    }
  }
}

.price-highlight {
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: $highlight-color;
  color: white;
  padding: 30px;
  border-radius: $border-radius;
  text-align: center;
  margin: 30px 0;

  &:hover {
    background-color: #f7805c;
  }

  h2 {
    font-size: 1.6rem;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 1.4rem;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.1rem;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .features-container {
    padding: 15px;
  }

  .feature-section {
    .section-title {
      font-size: 1.5rem;
    }

    .feature-content h3 {
      font-size: 1.2rem;
    }
  }

  .price-highlight {
    padding: 20px;

    h2 {
      font-size: 1.3rem;
    }

    h3 {
      font-size: 1.2rem;
    }

    p {
      font-size: 1rem;
    }
  }
}

@media (max-width: 480px) {
  .feature-section {
    padding: 15px;

    .section-title {
      font-size: 1.3rem;
    }
  }
}
.subscribe-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer; /* This will change the cursor to a pointer when hovering */
}



.reward-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 columns for desktop */
  gap: 20px;
  padding: 20px;
  grid-auto-flow: column; /* Ensure that the items are laid out in a single row */
  overflow-x: auto; /* Allows horizontal scrolling if necessary on smaller screens */
}

.reward-card {
  background-color: #ffffff; /* Crisp white background for clarity */
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: all 0.3s ease;
  min-width: 250px;
  border: 1px solid #dcdcdc; /* Subtle border for a refined touch */
}

/* Background color for each reward card (light pastel colors) */
.reward-card:nth-child(1) {
  background-color: #b3d9ff; /* Light pastel blue */
}

.reward-card:nth-child(2) {
  background-color: #f2d8b3; /* Light pastel peach */
}

.reward-card:nth-child(3) {
  background-color: #c8e6c9; /* Light pastel mint green */
}

.reward-card:nth-child(4) {
  background-color: #f7c0d7; /* Light pastel pink */
}

.reward-card:nth-child(5) {
  background-color: #f0f0f0; /* Light pastel gray */
}

/* Header styles with refined colors */
.reward-card h3 {
  margin: 10px 0;
  font-size: 1.2em;
  color: #2c3e50; /* Elegant dark gray for a formal and professional look */
}

/* Subheading for percentages with subtle but formal tones */
.reward-percentage {
  font-size: 1.5em;
  color: #2980b9; /* Muted blue for a professional, calm appearance */
}

/* Price styles */
.reward-card .price {
  font-size: 1.6em;
  font-weight: bold;
  color: #e74c3c; /* Classic red for emphasis on the price */
  margin-bottom: 10px;
}

/* Styling for the "FREE for the first year" text */
.reward-card p {
  font-size: 1em;
  color: #7f8c8d; /* Neutral gray for the descriptive text */
}

/* Hover effect for card interaction */
.reward-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Slight elevation on hover */
}

/* Responsive Design for Tablets and Mobile */
@media (max-width: 768px) {
  .reward-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 columns layout for tablets */
  }

  .reward-card {
      padding: 15px;
      font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .reward-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns layout for small screens */
  }

  .reward-card {
      padding: 10px;
      font-size: 0.8em;
  }
}



h1, h2, h3, h4, h5, h6 {
  text-align: center; /* Centrally align all headings */
  margin: 0 auto 10px; /* Optional: auto margin for alignment and add 10px bottom space */
}

p {
  margin-top: 10px; /* Add space above paragraphs */
}


/* Ensure the parent container is centered */
.highlight-text-container {
  text-align: center; /* Centers the button */
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  align-items: center; /* Centers the button vertically, if needed */
  height: 100%; /* Optional: Ensure there's enough space to center vertically */
}

/* Styling for the CTA button */
.cta-button {
  background-color: #3498db; /* Blue background color */
  color: white; /* White text color */
  padding: 15px 30px; /* Add padding around the text */
  text-decoration: none; /* Remove underline from the link */
  font-size: 18px; /* Font size */
  border-radius: 5px; /* Optional: rounded corners */
  display: inline-block; /* Ensures the link behaves like a button */
  text-align: center; /* Ensures the text is centered within the button */
  transition: background-color 0.3s ease;
}

/* Hover effect for the CTA button */
.cta-button:hover {
  background-color: #1d69b7; /* Darker blue when hovered */
}
