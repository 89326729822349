$blue: #342dfc;

$mobile: 480px;
$tablet: 800px;
$laptop: 1024px;
$desktop: 1300px;

.startups_container, .home_container {
  min-height: calc(100vh - 6.6rem);
  width: 100%;
  display: flex;
  flex-direction: column;

  footer {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    margin-top: auto;
  }
}

.startups_container {
  margin-top: 5rem;
  max-width: 1300px;
  margin: 0 auto;
}
.startups_search_box {
  margin: 30px 0;
  display: grid;
  grid-template-columns: 1fr 25fr 8fr;
  grid-template-rows: 1fr;
  .search_grid_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    grid-gap: 10px;

    input {
      border: 2px double rgba(52, 45, 252, 0);
      background-color: rgba(240, 240, 240, 0.7);
      height: 100%;
      width: 80%;
      border-radius: 25px;
      padding: 15px 20px;
      font-size: 16px;
      font-weight: 500;
      transition: border 0.2s ease-in-out;
      &:focus-within {
        outline: none;
        border: 2px double rgba(81, 23, 163,0.8);
      }
    }
    p {
      font-size: clamp(14px, 2vw, 18px);
    }
    select {
      border: 2px double rgba(81, 23, 163,0.4);
      background-color: white;
      border-radius: 8px;
      padding: 10px;
      &:focus-visible{
        border: 2px double rgba(81, 23, 163,0.8);
      }
      option{
        &:hover,
        &:focus {
          background-color: rgba(52, 45, 252, 0.1);
          color: rgba(81,23,163,1);
        }
      }
      &:focus-within {
        outline: none;
      }
    }
  }
  .search_grid_box:last-child {
    justify-content: end;
  }
}

.startups_post_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .startups_post_grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 15px;

    .startup_grid_box {
      background-color: #f1f1f1;
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 10px;
      min-width: 280px;
      height: 380px;
      position: relative;
      bottom: 0;
      transition: background-color 0.3s ease-in-out, bottom 0.1s ease-in-out, border 0.1s ease-in-out;
      border: 2px double rgba(255,255,252,0) ;

      &:hover {
        background-color: #f0f0f0;
        position: relative;
        bottom: 10px;
        cursor: pointer;
        border: 2px double rgba(81, 23, 163,0.8);
      }

      .box_img {
        aspect-ratio: 16/9;
        height: 180px;
        overflow: hidden;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          transition: transform 0.3s ease;
        }
      }

      .box_content {
        background-color: white;
        border-radius: 3px;
        list-style: none;
        padding: 5px 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;


        .date_title {
          display: flex;
          gap: 10px;
          align-items: center;
          margin-bottom: 10px;
          flex-shrink: 0;

          li {
            padding: 5px;
            text-align: center;
            &:first-child {
              color: #666;
              font-size: 14px;
            }
            &:last-child {
              font-size: 16px;
              font-weight: 500;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .post_content {
          border-radius: 5px;
          flex: 1 1 100%;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 8;
          -webkit-box-orient: vertical;
          font-size: 15px;
          line-height: 1.5;
          margin: 0;
          padding: 15px;
          width: 100%;
          min-width: 100%;
          box-sizing: border-box;
          word-break: break-word;
          max-height: 200px;
          text-overflow: ellipsis;
        }
      }

      &:first-child {
        grid-column: 1 / -1;
        flex-direction: row;
        height: auto;
        min-height: 400px;
        max-height: 500px;

        .box_img {
          flex: 0 0 40%;
          height: 400px;
          max-height: 400px;
        }

        .box_content {
          flex: 1;
          padding: 20px;
          display: flex;
          flex-direction: column;
          overflow: hidden;

          .date_title {
            margin-bottom: 20px;
            .post_date{
              font-size: 22px;
            }
            li:last-child {
              font-size: 24px;
              white-space: normal;
              line-height: 1.2;
            }
          }

          .post_content {
            display: -webkit-box;
            -webkit-line-clamp: 8;
            text-align: justify;
            width: 100%;
            font-size: 18px;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
.more_btn_cont {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  margin-top: 10px;
  .more_button{
    background-color: $blue;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    &:hover {
      background-color: #1e1f1f;
    }
  }
}
.errors {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 16px;
}

@media screen and (max-width: $tablet) {
  .startups_search_box {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: $mobile) {
  .startups_search_box {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .startups_post_grid {
    grid-template-columns: 1fr !important;
    gap: 15px;
  }
}

@media screen and (max-width: 1200px) {
  .startups_post_grid {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media screen and (max-width: 992px) {
  .startups_post_grid {
    grid-template-columns: repeat(2, 1fr) !important;

    .startup_grid_box {
      &:first-child {
        flex-direction: column !important;

        .box_img {
          width: 100%;
          height: 300px;
        }

        .box_content {
          padding: 20px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .startups_post_grid {
    grid-template-columns: 1fr !important;
    gap: 15px;

    .startup_grid_box {
      min-width: 100%;
      height: auto;

      .box_img {
        height: 200px;
      }
    }
  }
}

@media (max-width: 880px) {
  .startups_search_box {
    grid-template-columns: 1fr 10fr 6fr;
  }
  .search_grid_box {
    svg {
      height: 24px;
      width: 24px;
    }
  }
}

.highlight-yellow-text-container {
  text-align: center;
  margin-bottom: 20px;
}

.highlight-yellow-text {
  background-color: yellow;
  display: inline-block;
  padding: 8px;
  border-radius: 8px;
  font-weight: bold;
}

.highlight-orange {
  background-color: orange;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  color: white;
  font-size: 21px;
}
