.editor{
    max-width: 1300px;
    margin: 0 auto;
	display: flex;
	justify-content: center;
	align-content: center;
	min-height: 80svh;
}
.jodit-wysiwyg {
    font-weight: normal !important;
    p, div, span {
        font-weight: normal !important;
    }
}
.jodit-wysiwyg ul,
.jodit-dialog__content ul {
	list-style-type: disc;
	margin: 0;
	padding: 0 20px 10px;
}

.jodit-wysiwyg ul ul,
.jodit-dialog__content ul ul {
	list-style-type: circle;
}

.jodit-wysiwyg ul ul ul,
.jodit-dialog__content ul ul ul {
	list-style-type: square;
}

.jodit-wysiwyg ol,
.jodit-dialog__content ol {
	list-style-type: decimal;
	padding-left: 40px;
	margin-top: 1em;
	margin-bottom: 1em;
}

.jodit-wysiwyg ol ol,
.jodit-dialog__content ol ol {
	margin-top: 0;
	margin-bottom: 0;
}

.jodit-wysiwyg td,
.jodit-dialog__content td {
	border: solid 1px;
}

.jodit-container {
    .jodit-workplace {
        height: 700px !important;
    }
}

select{
	margin: 1rem 0;
	padding: 0.5rem;
	width: 200px;
}

.post_details{
	input{
		border: none;
		margin: 1rem 0;
		padding: 0.5rem;
		width: 400px;
		border: 1px double rgba(52, 45, 252, 0.5);
		&:focus{
			outline: none;
		}
	}
	button{
	margin: 1rem 0;
	padding: 0.5rem;
	border: none;
	width: 200px;
	background-color: #342dfc;
	color: white;
	cursor: pointer;
	&:hover{
		background-color: #3b50f8;
	}
	}
}

.text_editor_container {
  .input_container {
    .image_upload {
      margin: 1rem 0;
      padding: 1rem;
      border: 2px dashed #ccc;
      border-radius: 4px;

      .image_input {
        width: 100%;
        padding: 0.5rem;
      }

      .image_preview {
        margin-top: 0.5rem;
        padding: 0.5rem;
        background: #f5f5f5;
        border-radius: 4px;

        p {
          margin: 0;
          color: #666;
        }
      }
    }
  }
}
.uploading{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
}